import { useState, useEffect } from 'react';
import { getCsrfToken } from '../../csrfTokenHelper';

export const useFetchActiveSubPerformance = (token, exam_name) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [cpsPaper, setCpsPaper] = useState(null);
    const [pdPaper, setPdPaper] = useState(null);
    const [cpsPerformance, setCpsPerformance] = useState(null);
    const [cpsCoverage, setCpsCoverage] = useState(null);
    const [pdPerformance, setPdPerformance] = useState(null);
    const [pdCoverage, setPdCoverage] = useState(null);

    useEffect(() => {
        if (!token || !exam_name) {
            return; 
        }
        const fetchPerformance = async () => {
            try {
                const csrfToken = getCsrfToken();

                let response = await fetch(`${process.env.REACT_APP_API_URL}custom_api/MSRA/user/analytics/active_sub_summary/?exam_name=${encodeURIComponent(exam_name)}`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "X-CSRFToken": csrfToken,  // Add CSRF token to headers
                    },
                });

                if (!response.ok) throw new Error('Failed to fetch user performance');

                const data = await response.json();
                setData(data);

                if(exam_name === "MSRA"){
                    // Extract cpsPaper and pdPaper data
                    // Ensure data.papers exists before calling .find
                    const cpsPaperData = data.papers ? data.papers.find(paper => paper.name === 'Clinical Problem Solving') : null;
                    const pdPaperData = data.papers ? data.papers.find(paper => paper.name === 'Professional Dilemmas') : null;

                    setCpsPaper(cpsPaperData ? cpsPaperData : null);
                    setCpsPerformance(cpsPaperData ? cpsPaperData.performance : null);
                    setCpsCoverage(cpsPaperData ? cpsPaperData.coverage : null);
                    setPdPaper(pdPaperData ? pdPaperData : null);
                    setPdPerformance(pdPaperData ? pdPaperData.performance : null);
                    setPdCoverage(pdPaperData ? pdPaperData.coverage : null);
                } else {
                    // Extract cpsPaper and pdPaper data for Free Trial
                    const cpsPaperData = data.papers ? data.papers.find(paper => paper.name === 'Clinical Problem Solving Free Trial') : null;
                    const pdPaperData = data.papers ? data.papers.find(paper => paper.name === 'Professional Dilemmas Free Trial') : null;

                    setCpsPaper(cpsPaperData ? cpsPaperData : null);
                    setCpsPerformance(cpsPaperData ? cpsPaperData.performance : null);
                    setCpsCoverage(cpsPaperData ? cpsPaperData.coverage : null);
                    setPdPaper(pdPaperData ? pdPaperData : null);
                    setPdPerformance(pdPaperData ? pdPaperData.performance : null);
                    setPdCoverage(pdPaperData ? pdPaperData.coverage : null);
                }
            } catch (error) {
                console.error('Error fetching user performance:', error);
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchPerformance();
    }, [token, exam_name]);

    return { data, loading, error, cpsPaper, pdPaper, cpsPerformance, cpsCoverage, pdPerformance, pdCoverage };
};
