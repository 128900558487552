import React, {useState, useEffect} from "react"
import { Tabs, TabsList, TabsTrigger } from "../../../../components/shadcn/ui/tabs"
import { useFetchMSRAPapers } from "../../../../components/hooks/MSRA/fetches/FetchMSRAPapers"
import { useFetchCPSCategories } from "../../../../components/hooks/MSRA/fetches/FetchCPSCategories"
import { useFetchCPSSubcategories } from "../../../../components/hooks/MSRA/fetches/FetchCPSSubcategories"
import { useParams } from "react-router-dom"
import PaperPerformance from "../../../../components/dashboard/MSRA/PaperPerformance"
import CategoryPerformance from "../../../../components/dashboard/MSRA/CategoryPerformance"
import SubcategoryPerformance from "../../../../components/dashboard/MSRA/SubcategoryPerformance"
import { useFetchActiveSubPerformance } from "../../../../components/hooks/MSRA/user_performance/FetchActiveSubPerformance"
import { useFetchUserPercentile } from "../../../../components/hooks/MSRA/fetches/dashboard/FetchUserPercentile"
import { useFetchHistogramData } from "../../../../components/hooks/MSRA/fetches/dashboard/FetchHistogram"
import CustomLoader from "../../../../components/loader/CustomLoader"
import HeaderLarge from '../../../../components/typography/HeaderLarge'
import { useAuth0 } from "@auth0/auth0-react"
import SpinLoader from "../../../../components/loader/SpinLoader"

function TrialMSRADashboard() {
    const [selectedTab, setSelectedTab] = useState('paper');
    const [selectedPaper, setSelectedPaper] = useState('Clinical Problem Solving Free Trial')
    const [selectedCategory, setSelectedCategory] = useState('General Medicine Free Trial')
    const [selectedSubcategory, setSelectedSubcategory] = useState('Endocrinology Free Trial')
    // paper tab variables
    const [CPSdata, setCPSdata] = useState()
    const [PDdata, setPDdata] = useState()
    const [stateVariableLoading, setStateVariableLoading] = useState(true)
    // category tab variables
    const [categoryData, setCategoryData] = useState()
    //  subcategory tab variables
    const [subcategoryData, setSubcategoryData] = useState()

    const [token, setToken] = useState(null)
    const [tokenLoading, setTokenLoading] = useState(true)
    const { getAccessTokenSilently } = useAuth0()
    const [paperPercentile, setPaperPercentile] = useState()
    const [categoryPercentile, setCategoryPercentile] = useState()
    const [subcategoryPercentile, setSubcategoryPercentile] = useState()

    useEffect(() => {
        const fetchToken = async () => {
            try {
                const token = await getAccessTokenSilently()
                setToken(token)
            } catch (error) {
                console.error('Error fetching token: ', error);
            } finally {
                setTokenLoading(false)
            }
        }
        fetchToken()
    }, [getAccessTokenSilently])
    
    

    // 
    const { papers, loading, error } = useFetchMSRAPapers(token, "MSRA Free Trial");
    const { categoryNames, loading: catLoading, error: catError } = useFetchCPSCategories(token, "Clinical Problem Solving Free Trial");
    const { subcategoryNames, loading: subcatLoading, error: subcatError } = useFetchCPSSubcategories(token, "Clinical Problem Solving Free Trial");
    const { data, loading: paperLoading, error: paperError } = useFetchActiveSubPerformance(token, "MSRA Free Trial")
    const {data: paperPercentileData, loading: paperPercentileLoading, error: paperPercentileError} = useFetchUserPercentile('?types=papers', token, "MSRA Free Trial")
    const {data: histogramData, loading: histogramLoading, error: histogramError} = useFetchHistogramData(token, "MSRA Free Trial")
    const {data: categoryPercentileData, loading: categoryPercentileLoading, error: categoryPercentileError} = useFetchUserPercentile('?types=categories', token, "MSRA Free Trial")
    const {data: subcategoryPercentileData, loading: subcategoryPercentileLoading, error: subcategoryPercentileError} = useFetchUserPercentile('?types=subcategories', token, "MSRA Free Trial")
    // 
    const allLoading = catLoading || subcatLoading || paperLoading || paperPercentileLoading || histogramLoading || categoryPercentileLoading || subcategoryPercentileLoading || tokenLoading || stateVariableLoading

    useEffect(() => {
            if (paperLoading || histogramLoading) return; // Ensure data is loaded and not waiting on histogram data
    
            const cpsPaper = data.papers?.find(paper => paper.name === "Clinical Problem Solving Free Trial");
            if (cpsPaper) {
                setCPSdata(cpsPaper);
                const paperScore = Math.floor(cpsPaper.performance?.performance_percentage);
                if (histogramData && paperScore !== undefined) {
                    setPaperPercentile((100 - histogramData.papers[selectedPaper][`${paperScore}%`]?.cumulative_proportion)?.toFixed(1));
                }
            }
    
            const pdPaper = data.papers?.find(paper => paper.name === "Professional Dilemmas Free Trial");
            if (pdPaper) {
                setPDdata(pdPaper);
            }
    
            const categoryInfo = data.categories?.find(item => item.name === selectedCategory);
            if (categoryInfo) {
                setCategoryData(data.categories);
                const categoryScore = Math.floor(categoryInfo.performance?.performance_percentage);
                if (histogramData && categoryScore !== undefined) {
                    setCategoryPercentile((100 - histogramData.categories[selectedCategory][`${categoryScore}%`]?.cumulative_proportion)?.toFixed(1));
                }
            }
    
            const subcategoryInfo = data.subcategories?.find(item => item.name === selectedSubcategory);
            if (subcategoryInfo) {
                setSubcategoryData(data.subcategories);
                const subcategoryScore = Math.floor(subcategoryInfo.performance?.performance_percentage);
                if (histogramData && subcategoryScore !== undefined) {
                    setSubcategoryPercentile((100 - histogramData.subcategories[selectedSubcategory][`${subcategoryScore}%`]?.cumulative_proportion)?.toFixed(1));
                }
            }
    
            setStateVariableLoading(false);
        }, [data, histogramData, selectedCategory, selectedPaper, selectedSubcategory, histogramLoading, paperLoading]);
    

    if (allLoading) return <SpinLoader />
  return (
    <div>
        <div className="mx-auto p-6 text-card-foreground text-center w-full sm:w-3/4 lg:w-2/3">
        <HeaderLarge text={'My Analytics'} blackText={true} />
        </div>
        {/* Navigation Tabs */}
        <div className="mx-auto px-4 w-full sm:w-3/4 lg:w-1/2">
        <Tabs defaultValue="paper" >
            <TabsList className="grid w-full grid-cols-3">
            <TabsTrigger className="text-xs md:text-sm" value="paper" onClick={() => setSelectedTab('paper')}>Papers</TabsTrigger>
            <TabsTrigger className="text-xs md:text-sm" value="category" onClick={() => setSelectedTab('category')}>Categories</TabsTrigger>
            <TabsTrigger className="text-xs md:text-sm" value="subcategory" onClick={() => setSelectedTab('subcategory')}>Subcategories</TabsTrigger>
            </TabsList>
        </Tabs>
        </div>
        <div>
            {selectedTab === "paper" ? (
                <PaperPerformance 
                selectedPaper={selectedPaper}
                CPSdata={CPSdata}
                PDdata={PDdata}
                paperPercentile={paperPercentile}
                setSelectedPaper={setSelectedPaper}
                papers={papers}
                histogramData={histogramData.papers}
                histogramError={histogramError}
                paperError={paperError}
                trial={true}
                />
            ) : selectedTab === "category" ? (
                <CategoryPerformance
                selectedCategory={selectedCategory}
                data={categoryData}
                categoryPercentile={categoryPercentile}
                setSelectedCategory={setSelectedCategory}
                categoryNames={categoryNames}
                histogramData={histogramData.categories}
                histogramError={histogramError}
                paperError={paperError}
                trial={true}
                />
            ) : selectedTab === 'subcategory' ? (
                <SubcategoryPerformance
                selectedSubcategory={selectedSubcategory}
                data={subcategoryData}
                subcategoryPercentile={subcategoryPercentile}
                setSelectedSubcategory={setSelectedSubcategory}
                subcategoryNames={subcategoryNames}
                histogramData={histogramData.subcategories}
                histogramError={histogramError}
                paperError={paperError}
                trial={true}
                />
            ) : ''}
        </div>
    </div>
  )
}

export default TrialMSRADashboard