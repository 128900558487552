import React from 'react'
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from '../../../components/shadcn/ui/select'
import CoverageCard from './cards/CoverageCard';
import PerformanceCard from './cards/PerformanceCard';
import PercentileCard from './cards/PercentileCard';
import { Separator } from '../../../components/shadcn/ui/separator';
import BarChart from '../../visualisation/BarChart';
import PieChart from '../../visualisation/PieChart';
import HeaderMedium from '../../../components/typography/HeaderMedium';


function CategoryPerformance({selectedCategory, data, setSelectedCategory, categoryNames, histogramData, categoryPercentile, trial}) {
  const selectedCat = data?.find(cat => cat.name === selectedCategory)

  
  return (
    <div>
      <div className="container mx-auto p-4">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="text-xl font-semibold text-center md:text-left">
            {selectedCategory ? <div>{selectedCategory}</div> : "Please select a category"}
          </div>
          <div className="mt-2 md:mt-0">
            <Select onValueChange={setSelectedCategory}>
              <SelectTrigger className="w-[200px]">
                <SelectValue placeholder="Change category..." />
              </SelectTrigger>
              <SelectContent>
              {categoryNames.map((cat, index) => {
                return <SelectItem id={index} value={cat.name}>{cat.name}</SelectItem>
                })}
              </SelectContent>
            </Select>
          </div>
        </div>
        <Separator className='my-4' orientation='horizontal' />
      {selectedCat && (
        <>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-4">
            <div className="flex justify-center align-center text-center">
              <div className="mt-4 hidden lg:inline" >
                <PieChart
                  title="Coverage"
                  dashboard={true}
                  data={[
                    { color: 3, label: 'Not viewed', value: selectedCat.coverage.total_questions - selectedCat.coverage.total_questions_answered },
                    { color: 2, label: 'Skipped', value: 0 },
                    { color: 1, label: 'Attempted', value: selectedCat.coverage.total_questions_answered },
                  ]}
                  scoreChart={false}
                  mobile={false}
                />
              </div>
              <div className="mt-4 hidden md:inline lg:hidden" >
                <PieChart
                  title="Coverage"
                  dashboard={true}
                  data={[
                    { color: 3, label: 'Not viewed', value: selectedCat.coverage.total_questions - selectedCat.coverage.total_questions_answered },
                    { color: 2, label: 'Skipped', value: 0 },
                    { color: 1, label: 'Attempted', value: selectedCat.coverage.total_questions_answered },
                  ]}
                  scoreChart={false}
                  mobile={false}
                />
              </div>
              <div className="p-0 sm:hidden inline">
                <PieChart
                  title="Coverage"
                  dashboard={true}
                  data={[
                    { color: 3, label: 'Not viewed', value: selectedCat.coverage.total_questions - selectedCat.coverage.total_questions_answered },
                    { color: 2, label: 'Skipped', value: 0 },
                    { color: 1, label: 'Attempted', value: selectedCat.coverage.total_questions_answered },
                  ]}
                  scoreChart={false}
                  mobile={true}
                />
              </div>
            </div>
            <div className="inline w-full lg:w-2/5 md:w-4/5 md:mx-auto lg:hidden">
              <CoverageCard 
                selected={selectedCat}
                categoryCard={true}
              />
            </div>
            <div className="flex justify-center align-center text-center">
              <div className="mt-4 hidden lg:inline" >
                {/* Desktop */}
                  <PieChart
                    title="Score"
                    dashboard={true}
                    data={[
                      { color: 2, label: 'Incorrect', value: selectedCat.performance.total_possible_points - selectedCat.performance.total_points_scored },
                      { color: 1, label: 'Correct', value: selectedCat.performance.total_points_scored },
                    ]}
                    scoreChart={true}
                    mobile={false}
                  />
                </div>
                <div className="mt-4 hidden md:inline lg:hidden">
                {/* Tablet */}
                  <PieChart
                    title="Score"
                    dashboard={true}
                    data={[
                      { color: 2, label: 'Incorrect', value: selectedCat.performance.total_possible_points - selectedCat.performance.total_points_scored },
                      { color: 1, label: 'Correct', value: selectedCat.performance.total_points_scored },
                    ]}
                    scoreChart={true}
                    mobile={false}
                  />
                </div>
                <div className="p-0 sm:hidden inline">
                  {/* Mobile */}
                  <PieChart
                    title="Score"
                    dashboard={true}
                    data={[
                      { color: 2, label: 'Incorrect', value: selectedCat.performance.total_possible_points - selectedCat.performance.total_points_scored },
                      { color: 1, label: 'Correct', value: selectedCat.performance.total_points_scored },                    ]}
                    scoreChart={true}
                    mobile={true}
                  />  
                </div>
            </div>
            <div className="inline w-full lg:w-2/5 md:w-4/5 md:mx-auto lg:hidden">
              <PerformanceCard 
                selected={selectedCat}
                categoryCard={true}
              />
            </div>
          </div>
          {/* performance and coverage cards in non-mobile view */}
          <div className="hidden lg:inline">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
              <div className="w-3/4 mx-auto">
                <CoverageCard
                  selected={selectedCat}
                  categoryCard={true}
                />
              </div>
              <div className="w-3/4 mx-auto">
                <PerformanceCard
                  selected={selectedCat}
                  categoryCard={true}
                />
              </div>
            </div>
          </div>
          {/* Histogram */}
          <div className="my-4">
            <div className="lg:w-full md:w-2/3 w-full mx-auto mb-4">
              {trial ? (
                <span className='text-center'>
                  <HeaderMedium
                  text={"Histogram only available in the full version due to low number of questions per topic."}
                  blackText={true}
                  mx={2}
                  underline={false}
                  my={2}
                  />
                </span>
              ) : (
                <>
                  <div className="text-center py-4">
                    <div className="font-semibold text-2xl sm:text-3xl">
                      Percentile by Score
                    </div>
                  </div>
                  <div className="mt-4 hidden lg:inline" >
                    <BarChart data={histogramData[selectedCategory]} performancePercentage={selectedCat.performance.performance_percentage} viewPort='large' />
                  </div>
                  <div className="mt-4 hidden md:inline lg:hidden">
                    <BarChart data={histogramData[selectedCategory]} performancePercentage={selectedCat.performance.performance_percentage} viewPort='medium' />
                  </div>
                  <div className="p-0 sm:hidden inline">
                    <BarChart data={histogramData[selectedCategory]} performancePercentage={selectedCat.performance.performance_percentage} viewPort='small' />
                  </div>
                </>
              )}
                
            </div>
            <div className="w-full lg:w-2/5 md:w-4/5 md:mx-auto">
              <PercentileCard
              percentile={categoryPercentile}
              categoryCard={true}
              /> 
            </div>
          </div>
        </>
      )}
      </div>
      {selectedCategory && !selectedCat && (
      <>
        <div className="text-center text-black dark:text-white text-2xl font-bold">
          No data to analyze yet.
        </div>
      </>
      )}
    </div>
  )
}

export default CategoryPerformance