import { useState, useEffect } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import '../../styles/index.css';
import { useTheme } from '../common/ThemeContext';

function BarChart({ data, performancePercentage, viewPort, homePage }) {
  const { theme } = useTheme();
  const barColor = theme === 'light' ? 'hsl(222.2, 67%, 28%)' : 'hsl(222.2, 67%, 70%)';
  const textColor = theme === 'light' ? 'black' : 'hsl(210 40% 98%)';
  const toolTipBackground = theme === 'light' ? 'white' : 'hsl(223 47% 11%)';
  const graphHeight = viewPort === 'small' ? 300 : viewPort === 'medium' ? 400 : viewPort === 'large' ? 500 : 0;
  const axisLegendSize = viewPort === 'small' ? 12 : viewPort === 'medium' ? 16 : viewPort === 'large' ? 18 : 0;

  const [nivoData, setNivoData] = useState([]);

  useEffect(() => {
    // Recalculate nivoData when performancePercentage or data changes
    const newNivoData = Object.entries(data).map(([key, value]) => {
      const isHighlighted = key === `${Math.floor(performancePercentage)}%`;  // Highlight based on the percentage
      return {
        category: key,
        percentage: value['proportion_of_users'],
        cumPercentage: value['cumulative_proportion'],
        highlighted: isHighlighted,
      };
    });
    setNivoData(newNivoData); // Update state with the newly calculated data
  }, [data, performancePercentage]); // Recalculate when data or performancePercentage changes

  // Custom Tooltip Function
  const customTooltip = (bar) => (
    <div 
      style={{
        padding: '5px 10px',
        background: toolTipBackground,
        border: theme === 'light' ? '1px solid black' : '1px solid hsl(210 40% 98%)',
      }}
    >
      {homePage ? (
        <strong>{bar.data.cumPercentage.toFixed(1)}% of users have a score higher than {bar.data.category}.</strong>
      )
      : (
        <strong>{(100 - bar.data.cumPercentage).toFixed(1)}% of users have a score higher than {bar.data.category}.</strong>
      )} 
    </div>
  );

  const customTheme = {
    axis: {
      ticks: {
        line: {
          stroke: textColor,
        },
        text: {
          fill: textColor
        }
      },
      legend: {
        text: {
          fontSize: axisLegendSize, // Increase font size
          fontWeight: 'bold', // Make text bold
          fill: textColor,
        },
      },
    },
  };

  const tickValues = viewPort === 'small' ? Object.keys(data).filter((_, index) => index % 10 === 0) : Object.keys(data).filter((_, index) => index % 5 === 0);

  return (
    <div style={{height: graphHeight}}>
      {/* Using key to force re-render on data change */}
      <ResponsiveBar
        key={JSON.stringify(nivoData)} // Use JSON.stringify to create a unique key based on data
        data={nivoData}
        keys={['percentage']}
        indexBy="category"
        margin={{ top: 10, right: 0, bottom: 70, left: 55 }}
        padding={0}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        colors={({ data }) => {
          return data.highlighted ? 'hsl(120, 53%, 47%)' : barColor;
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickValues: tickValues,
          tickSize: 5,
          tickPadding: 5,
          tickRotation: -40,
          legend: 'Score',
          legendPosition: 'middle',
          legendOffset: 45,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Proportion of Users',
          legendPosition: 'middle',
          legendOffset: -45,
          format: (d) => `${d}%`,
        }}
        animate={true}
        enableLabel={false}
        tooltip={customTooltip}
        barAriaLabel={(e) => `${e.id}: ${e.value}%`}
        theme={customTheme}
      />
    </div>
  );
}

export default BarChart;
