import { useState } from 'react'
import { Alert, AlertTitle, AlertDescription } from '../shadcn/ui/alert'
import { Button } from "../shadcn/ui/button"
import { XIcon } from 'lucide-react'

export default function AlertBanner({title, text}) {
  const [isVisible, setIsVisible] = useState(true)

  if (!isVisible) return null

  return (
    <Alert className="relative mb-4 max-w-lg mx-auto">
      <AlertTitle className="text-lg font-semibold">{title}</AlertTitle>
      <AlertDescription className="mt-2">
        {text}
      </AlertDescription>
      <Button
        variant="outline"
        size="icon"
        className="absolute top-2 right-2"
        onClick={() => setIsVisible(false)}
        aria-label="Dismiss alert"
      >
        <XIcon className="h-4 w-4" />
      </Button>
    </Alert>
  )
}
