import React, { useEffect, useState, useReducer } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFetchTextbookByExam } from '../../../../components/hooks/MSRA/fetches/textbook/FetchTextbookByExam';
import { useFetchSingleTextbookItem } from '../../../../components/hooks/MSRA/fetches/textbook/FetchSingleTextbook';
import { useAuth0 } from '@auth0/auth0-react';
import SpinLoader from '../../../../components/loader/SpinLoader';
import TextbookSideBar from '../../../../components/textbook/TextbookSideBar';
import TextbookDisplay from '../../../../components/textbook/TextbookDisplay';
import TextbookMobileSearch from '../../../../components/textbook/TextbookMobileSearch';

// Reducer function to manage collapsible state
function collapsibleReducer(state, action) {
  switch (action.type) {
    case 'TOGGLE_CATEGORY':
      return {
        ...state,
        [action.payload]: !state[action.payload], // Toggle the state of the category
      };
    default:
      return state;
  }
}

function MSRATextbook() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [searchTerm, setSearchTerm] = useState('');
  const [sheetIsOpen, setSheetIsOpen] = useState(false);
  const [token, setToken] = useState(null);
  const [tokenLoading, setTokenLoading] = useState(true);

  // Manage collapsible categories state using useReducer
  const [openCategories, dispatch] = useReducer(collapsibleReducer, {});

  const { getAccessTokenSilently, isLoading: authLoading } = useAuth0();

  // Fetch token using Auth0
  useEffect(() => {
    const fetchToken = async () => {
      try {
        const fetchedToken = await getAccessTokenSilently();
        setToken(fetchedToken);
      } catch (error) {
        console.error('Error fetching token:', error);
      } finally {
        setTokenLoading(false);
      }
    };

    fetchToken();
  }, [getAccessTokenSilently]);

  // Fetch textbook items and single textbook item
  const { textbookItems: textbookList, loading: listLoading } = useFetchTextbookByExam(1, token);
  const { textbookItem, loading: itemLoading } = useFetchSingleTextbookItem(id, token);

  // Determine if loading state is active
  const allLoading = itemLoading || listLoading || tokenLoading || authLoading;

  // Handle sidebar sheet navigation for mobile view
  const sheetNavigate = (destination) => {
    navigate(destination);
    setSheetIsOpen(false);
  };

  if (allLoading) return <SpinLoader />;

  return (
    <div className="flex md:flex-row flex-col">
      {/* Sidebar */}
      <TextbookSideBar
        textbookItems={textbookList}
        navigate={navigate}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        exam={"MSRA"}
        openCategories={openCategories}
        toggleCategory={(category) => dispatch({ type: 'TOGGLE_CATEGORY', payload: category })}
      />

      {/* Mobile search and navigation */}
      <TextbookMobileSearch
        textbookItems={textbookList}
        navigate={navigate}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        exam={"MSRA"}
        sheetIsOpen={sheetIsOpen}
        setSheetIsOpen={setSheetIsOpen}
        sheetNavigate={sheetNavigate}
      />

      {/* Main content display */}
      <TextbookDisplay
        textbookItem={textbookItem}
        param={id}
        loading={allLoading}
      />
    </div>
  );
}

export default MSRATextbook;
