import React from 'react'
import { Card, CardHeader, CardTitle, CardContent } from '../../../../components/shadcn/ui/card'
import { Separator } from '../../../../components/shadcn/ui/separator'
import { Trophy } from 'lucide-react'

const PercentileCard = ({ categoryCard, percentile  }) => {

  return (
    <Card>
        <CardHeader>
            <div className='flex items-center justify-between'>
              <CardTitle className="text-sm font-medium">Performance Percentile</CardTitle>
              <Trophy size={16} />
            </div>
            <Separator orientation="horizontal" />
        </CardHeader>
        <CardContent>
          {categoryCard ? (
            <>
            <div className="text-2xl font-bold">
              {percentile}
              <span className="text-sm text-muted-foreground"> percentile</span>
            </div>
            <p className="text-xs text-muted-foreground">
            Your score is higher than {(100 - percentile).toFixed(1)}% of our users
            </p>
            </>
          ):(
            <>
            <div className="text-2xl font-bold">
              {percentile}
              <span className="text-sm text-muted-foreground"> percentile</span>
            </div>
            <p className="text-xs text-muted-foreground">
            Your score is higher than {(100 - percentile).toFixed(1)}% of our users
            </p>
            </>
          )}
        </CardContent>
    </Card>
  )
}

export default PercentileCard